import { useAppStore } from '@/stores/appStore';
import type { ApiResponse } from '@/types/api/global';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const token = to.params.token;
  const query = to.query.confirmEmail;
  const apiUri = import.meta.env.VITE_API_URL;
  const { updateState } = useAppStore();
  try {
    if (token && query) {
      const res: ApiResponse<{ user: Object }> = await $fetch(
        `${apiUri}/confirmEmail`,
        {
          method: 'POST',
          body: JSON.stringify({
            token: token,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (res.success) {
        updateState('messages.showConfirmedSignup', true);
      }
    }
    return navigateTo(
      { name: 'auth-index-login', hash: '#confirmed' },
      { replace: true },
    );
  } catch (e) {
    console.error(e);
    return navigateTo({ name: 'auth-index-login' }, { replace: true });
  }
});
